/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Styled, jsx, Textarea } from "theme-ui";
import { useThemeUI } from "theme-ui";

interface Props {
  name: string;
  placeholder: string;
  type?: string;
  disabled?: boolean;
}

export const Input: React.SFC<Props> = (props) => {
  const context = useThemeUI();
  const inputStyles = {
    height: "98px",
    backgroundColor: "background",
    fontSize: "24px",
    fontWight: "bold",
    lineHeight: ["unset", "28px"],
    color: "primary",
    border: "none",
    outline: "none",
    borderBottom: "1px solid",
    borderBottomColor: "primary",
    padding: "48px 0 0 0",
    "&:-internal-autofill-selected": {
      WebkitBoxShadow: `inset 0 0 0 50px ${context.theme.colors.background}`,
      WebkitTextFillColor: context.theme.colors.primary,
      WebkitAppearance: "none",
      backgroundColor: "background",
    },
  };
  const placeHolderStyle = {
    textTransform: "capitalize",
    fontSize: ["5rem", 5],
    lineHeight: "98px",
    WebkitTextFillColor: context.theme.colors.background,
    WebkitTextStrokeWidth: 1,
    WebkitTextStrokeColor: context.theme.colors.primary,
    padding: 0,
  };

  if (props.type === "area") {
    return (
      <textarea
        disabled={props.disabled}
        placeholder={props.placeholder}
        name={props.name}
        sx={{
          ...inputStyles,
          // @ts-ignore
          "&:placeholder-shown": placeHolderStyle,
        }}
      />
    );
  }

  return (
    <input
      disabled={props.disabled}
      name={props.name}
      type={props.type || "text"}
      sx={{
        ...inputStyles,
        // @ts-ignore
        "&:placeholder-shown": placeHolderStyle,
      }}
      placeholder={props.placeholder}
    />
  );
};

export default Input;
