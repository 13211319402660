import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Styled, jsx } from "theme-ui";
import { useThemeUI } from "theme-ui";
import bigArrow from "src/images/bigArrow.svg";

export const SendButton: React.SFC<{
  disabled?: boolean;
}> = (props) => {
  const context = useThemeUI();
  const buttonStyles = {
    backgroundColor: "background",
    color: "primary",
    border: "none",
    outline: "none",
    textTransform: "capitalize",
    fontSize: ["48px", "72px"],
    WebkitTextFillColor: context.theme.colors.background,
    WebkitTextStrokeWidth: 1,
    WebkitTextStrokeColor: context.theme.colors.primary,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&:disabled": {
      WebkitTextStrokeColor: context.theme.colors.secondary,
    },
  };
  return (
    /*
      // @ts-ignore */
    <button type="submit" sx={buttonStyles} disabled={props.disabled}>
      отправить
      <div
        sx={{
          ml: "5%",
        }}
      >
        <Styled.img src={bigArrow} />
      </div>
    </button>
  );
};

export default SendButton;
