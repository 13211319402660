import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";
import classes from "./PointText.module.scss";

interface Props {
  text: string;
  styles?: object;
}

export const PointText: React.SFC<Props> = (props: Props) => {
  return (
    <div
      className={classes.pointWrapper}
      sx={{
        mb: [5, 4],
        ...(props.styles || {}),
      }}
    >
      <div sx={{ backgroundColor: "secondary" }} className={classes.point} />
      <span>{props.text}</span>
    </div>
  );
};

export default PointText;
