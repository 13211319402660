const styles = {
  toggleWrapper: {
    backgroundColor: ["background"],
    right: "5vw",
    top: "2.5vh",
    height: [39, 58],
    width: [39, 58],
    zIndex: ["800", "100"],
    borderRadius: "50%",
  },
  toggleNavButton: {
    borderColor: "primary",
    borderStyle: "solid",
    borderRadius: "50%",
    borderWidth: ["s", "m"],
    height: [39, 58],
    width: [39, 58],
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  toggleLine: {
    borderTop: "1px solid",
    borderWidth: ["s", "m"],
    borderColor: "primary",
    width: "calc(100% - 12px)",
    height: "1px",
    margin: "3px auto",
  },
};

export default styles;
