import * as React from "react";

interface Props {
  link: string;
}

const WrapImage: React.SFC<Props> = (props) => {
  if (!props.link) {
    return <div>{props.children}</div>;
  }
  return (
    <a href={props.link} target="blank">
      {props.children}
    </a>
  );
};

export default WrapImage;
