import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Styled, jsx } from "theme-ui";
import OneScreenWrapper from "../OneScreenWrapper";
import PointText from "src/components/PointText";
import Logo from "../Start/Logo";
import gp1 from "src/images/aboutUs-gp1.svg";
import gp2 from "src/images/aboutUs-gp2.svg";
import gp3 from "src/images/aboutUs-gp3.svg";
import goToAboutUs from "src/images/goToAboutUs.svg";
import { Link } from "gatsby";

const AboutUs: React.SFC<{}> = () => {
  return (
    <div
      id="#aboutUs"
      sx={{
        position: "relative",
      }}
    >
      <OneScreenWrapper>
        <div>
          <PointText text="о нас" />
          <Styled.h1
            sx={{
              width: ["unset", "60%"],
            }}
          >
            Кто мы такие
          </Styled.h1>
          <img
            src={gp1}
            sx={{
              position: "absolute",
              bottom: "50px",
            }}
          />
        </div>
        <div
          sx={{
            width: ["unset", "50%"],
            marginRight: [0, "7%"],
          }}
        >
          <div>
            <Logo stroke />
          </div>
          <div
            sx={{
              mt: [0, 3],
            }}
          >
            <span>Shake Me — агентство молодых профессионалов.</span>
            <br />
            <span>
              Мы считаем все проекты приоритетными: уделяем им максимум внимания
              и несем ответственность за каждую запятую в коде или тексте. Наши
              клиенты получают только самые лучшие решения и не платят за
              просмотр Youtube нашими сотрудниками.
            </span>
          </div>
          <div
            sx={{
              marginY: [4],
            }}
          >
            <Link
              to="/aboutUs"
              sx={{
                height: 54,
                position: "relative",
              }}
            >
              <span
                sx={{
                  textDecoration: "none",
                  color: "secondary",
                  position: "absolute",
                }}
              >
                узнать больше
              </span>
              <img src={goToAboutUs} />
            </Link>
          </div>
        </div>

        <div
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            marginTop: [0, "-7%"],
            marginLeft: [0, "-14%"],
          }}
        >
          <img src={gp3} />
          <img src={gp2} />
        </div>
      </OneScreenWrapper>
    </div>
  );
};

export default AboutUs;
