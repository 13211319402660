import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Styled, jsx } from "theme-ui";

import Input from "./Input";
import SendButton from "./SendButton";

interface Props {
  handleSubmit: (
    event: React.SyntheticEvent<HTMLFormElement, Event>
  ) => Promise<void>;
  submitting?: boolean;
}

const ContactForm: React.SFC<Props> = (props) => {
  return (
    <form
      onSubmit={props.handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        width: ["100%", "60%"],
      }}
    >
      <Input name="name" placeholder="имя" disabled={props.submitting} />
      <Input
        name="email"
        placeholder="email"
        type="email"
        disabled={props.submitting}
      />
      <Input
        name="text"
        placeholder="text"
        type="area"
        disabled={props.submitting}
      />
      <SendButton disabled={props.submitting} />
    </form>
  );
};

export default ContactForm;
