import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";
import classes from "./SlidePointer.module.scss";

const SlidePointer: React.SFC<{}> = () => {
  return (
    <div
      className={classes.pointWrapper}
      sx={{
        position: "sticky",
        display: ["none", "flex"],
        right: "2vw",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <span
        sx={{
          color: "#828282",
          width: "max-content",
        }}
      >
        просто прокрутите
      </span>
      <div
        sx={{
          height: "1px",
          backgroundColor: "#828282",
          width: "64px",
          margin: "auto 12px",
        }}
      />
      <div sx={{ backgroundColor: "secondary" }} className={classes.point} />
    </div>
  );
};

export default SlidePointer;
