import React from "react";
import whiteHeaderLogo from "src/images/logo-white-small.svg";
import { Link } from "gatsby";
import styles from "./styles";
import classes from "./MainHeader.module.scss";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Styled, jsx } from "theme-ui";
import Anchor from "src/components/Anchor";
import vk from "src/images/icons/vk.svg";
import fb from "src/images/icons/fb.svg";
import inst from "src/images/icons/inst.svg";

const MainHeader: React.SFC<{}> = () => {
  const linkStyle = {
    display: "block",
    marginY: [2],
  };
  return (
    <header
      className={classes.headerWrapper}
      sx={{
        width: ["90%", "90%"],
        margin: ["24px 5vw", "auto"],
        height: ["unset", "10vh"],
        flexWrap: "nowrap",
        justifyContent: "space-between",
      }}
    >
      <div
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Link to="/">
          <Styled.img src={whiteHeaderLogo} />
        </Link>
        <nav
          className={classes.navWrapper}
          sx={{
            display: ["none", "grid"],
            columnGap: [12, "96px"],
            margin: ["12px auto", "auto"],
            ml: [0, "8vw"],
            width: ["100%", "unset"],
          }}
        >
          <Anchor to="#cases">
            <span>кейсы</span>
          </Anchor>
          <Anchor to="#services">
            <span>что делаем</span>
          </Anchor>
          <Anchor to="#aboutUs">о нас</Anchor>
          <Anchor to="#contacts">контакты</Anchor>
        </nav>
      </div>
      <div sx={styles.toggleWrapper}>
        <Link
          to="/navigation"
          state={{
            closeTo:
              (typeof window !== `undefined` &&
                window?.location?.pathname + window?.location?.hash) ||
              "/",
          }}
        >
          {/*
             // @ts-ignore */}
          <div sx={styles.toggleNavButton}>
            <div sx={styles.toggleLine} />
            <div sx={styles.toggleLine} />
          </div>
        </Link>
      </div>
      <div
        sx={{
          position: "fixed",
          right: "6.1vw",
          top: "12vh",
          display: "flex",
          flexDirection: "column",
          zIndex: 500,
        }}
      >
        <a href="https://vk.com/shakemeagency" sx={linkStyle}>
          <Styled.img src={vk} />
        </a>
        <a
          href="https://www.facebook.com/shakemeagency-109117127180629/"
          sx={linkStyle}
        >
          <Styled.img src={fb} />
        </a>
        <a href="https://www.instagram.com/shakeme.agency/" sx={linkStyle}>
          <Styled.img src={inst} />
        </a>
      </div>
    </header>
  );
};

export default MainHeader;
