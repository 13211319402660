import React from "react";
import Start from "./Start";
import S from "./Start/S";
import Cases from "./Cases";
import H from "./Start/H";
import Services from "./Services";
import AboutUs from "./AboutUs";
import SlidePointer from "src/components/SlidePointer";
import ContactForm from "src/components/ContactForm";

export const Main: React.SFC<{}> = () => {
  return (
    <>
      <Start />
      <div
        style={{
          display: "flex",
          marginLeft: "-14%",
        }}
      >
        <S />
        <H />
      </div>
      <Cases />
      <Services />
      <AboutUs />
      <SlidePointer />
      <ContactForm />
    </>
  );
};

export default Main;
