/* eslint-disable @typescript-eslint/ban-ts-ignore */
import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Styled, jsx } from "theme-ui";
import { useThemeUI } from "theme-ui";
import { Link } from "gatsby";

const ServiceHeader: React.SFC<{ icon: string; link: string }> = (props) => {
  const context = useThemeUI();

  const styles = {
    fontSize: ["5rem", 6],
    zIndex: 200,
    lineHeight: [0],
    WebkitTextFillColor: context.theme.colors.background,
    WebkitTextStrokeWidth: 1,
    WebkitTextStrokeColor: context.theme.colors.primary,
    ":hover": {
      transition: "0.5s",
      WebkitTextFillColor: context.theme.colors.primary,
      WebkitTextStrokeColor: context.theme.colors.background,
    },
  };

  return (
    <div
      sx={{
        mb: [5],
        ":last-of-type": {
          marginBottom: [0],
        },
        ":hover > img": {
          transition: "opacity 1s",
          visibility: "visible",
          opacity: 1,
        },
      }}
    >
      <Link
        to={props.link}
        sx={{
          textDecoration: "none",
          color: "transparent",
          zIndex: 200,
        }}
      >
        <Styled.h2 sx={styles}>{props.children}</Styled.h2>
      </Link>
      <img
        sx={{
          bottom: 100,
          left: "-15%",
          transition: "1s",
          opacity: 0,
          position: "absolute",
          visibility: "hidden",
          zIndex: 0,
          width: "50%",
        }}
        src={props.icon}
      />
    </div>
  );
};

export default ServiceHeader;
