import { PageProps } from "gatsby";
import * as React from "react";
import MainHeader from "src/components/MainHeader";
import SectionCounter from "src/components/SectionCounter";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";
import "src/index.css";
import Footer from "src/components/Footer";

interface Props extends Omit<PageProps, "children"> {
  children: React.ReactNode;
}

const MainLayout: React.SFC<Props> = (props) => {
  return (
    <div
      sx={{
        backgroundColor: "background",
        height: ["unset", "100vh"],
      }}
    >
      <MainHeader />
      <main
        sx={{
          height: ["unset", "85vh"],
          width: "100%",
          margin: ["auto"],
          display: "flex",
          overflowX: ["hidden", "auto"],
          flexDirection: ["column", "row"],
        }}
      >
        <SectionCounter />
        <div
          sx={{
            overflowX: ["unset", "scroll"],
            overflowY: ["unset", "hidden"],
            display: "flex",
            flexDirection: ["column", "row"],
            position: "relative",
            paddingTop: 0,
            paddingLeft: [0, "5vw"],
            width: ["90%", "100%"],
            pt: "6%",
            margin: ["auto", 0],
          }}
        >
          {props.children}
        </div>
      </main>
      <Footer
        styles={{
          marginLeft: "20vw",
        }}
      />
    </div>
  );
};

export default MainLayout;
