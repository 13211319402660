import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";

const SectionCounter: React.SFC<{}> = () => {
  return (
    <div
      sx={{
        // position: "fixed",
        // left: "10%",
        paddingTop: "10vh",
        display: ["none", "none"],
      }}
    >
      01/06
    </div>
  );
};

export default SectionCounter;
