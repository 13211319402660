import React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";
import PointText from "src/components/PointText";
import OneScreenWrapper from "../OneScreenWrapper";
import ServiceHeader from "./ServiceHeader";
import { Styled } from "theme-ui";

import webIcon from "src/images/services/fullWeb.svg";
import content from "src/images/services/fullContent.svg";
import smmIcon from "src/images/services/fullSmm.svg";

const Services: React.SFC<{}> = () => {
  return (
    <div
      id="#services"
      sx={{
        position: "relative",
      }}
    >
      <OneScreenWrapper>
        <div>
          <PointText text="что делаем" />
          <Styled.h1>Сервисы</Styled.h1>
        </div>
        <div>
          <ServiceHeader icon={webIcon} link="/web">
            WEB
          </ServiceHeader>
          <ServiceHeader icon={content} link="/smm">
            SMM
          </ServiceHeader>
          <ServiceHeader icon={smmIcon} link="/content">
            Контент
          </ServiceHeader>
        </div>
      </OneScreenWrapper>
    </div>
  );
};

export default Services;
