import { PageProps } from "gatsby";
import * as React from "react";

import SEO from "src/components/Seo";
import MainLayout from "src/routes/MainLayout";
import Main from "src/routes/Main";

const IndexPage: React.SFC<PageProps> = (props: PageProps) => (
  <>
    <SEO
      pageTitle="Главная"
      pageDescription="Shake Me digital agency. Мы разрабатываем сайты и приложения. Ведем соцсети для бизнеса Instagram, Вконтакте, Facebook. Делаем брендинг, логотипы, полиграфию, фото и видео съемки."
    />
    <MainLayout {...props}>
      <Main />
    </MainLayout>
  </>
);

export default IndexPage;
