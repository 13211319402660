import React from "react";
import PointText from "src/components/PointText";
import OneScreenWrapper from "../../OneScreenWrapper";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Styled, jsx } from "theme-ui";
import Img, { GatsbyImageProps } from "gatsby-image";
import WrapImage from "./WarpImage";

interface Props {
  header: string;
  img?: string;
  gatsbyImg?: GatsbyImageProps["fluid"];
  link: string;
  subheader?: string;
  imgOverlay?: React.ReactNode;
}

const Case: React.SFC<Props> = (props: Props) => {
  return (
    <OneScreenWrapper>
      <div
        sx={{
          zIndex: 500,
          width: ["85%", "unset"],
        }}
      >
        <PointText text="наши кейсы" />
        <Styled.h1
          sx={{
            width: ["unset", "min-content"],
            fontSize: ["10vw", "64px"],
          }}
        >
          {props.header}
        </Styled.h1>
        <span
          sx={{
            fontSize: "1.31951rem",
            fontWeight: "bold",
          }}
        >
          {props.subheader}
        </span>
      </div>
      <div
        sx={{
          padding: ["13px", "21px"],
          border: "1px solid",
          borderColor: "primary",
          height: "fit-content",
          maxWidth: "480px",
          width: "70%",
        }}
      >
        <WrapImage link={props.link}>
          <div sx={{ position: "relative" }}>
            <div
              sx={{
                position: "absolute",
                zIndex: 100,
                color: "black",
                width: "100%",
                left: "5%",
                top: "2%",
              }}
            >
              {props.imgOverlay}
            </div>
            {props.gatsbyImg && (
              <Img fluid={{ ...props.gatsbyImg, aspectRatio: 1 }} />
            )}
            {props.img && <Styled.img src={props.img} alt={props.header} />}
          </div>
        </WrapImage>
      </div>
    </OneScreenWrapper>
  );
};

export default Case;
