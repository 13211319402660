// @jsx jsx
import * as React from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";

const H: React.SFC<{}> = () => {
  return (
    <div
      sx={{
        position: "sticky",
        opacity: 0.3,
        flexDirection: "column",
        justifyContent: "flex-end",
        display: ["none", "flex"],
      }}
    >
      <svg
        // width="479"
        height="347"
        viewBox="0 0 87 107"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={{
          "& > path": {
            stroke: "primary",
            strokeWidth: 0.5,
          },
        }}
      >
        <path
          d="M23.1135 106.509C15.5171 106.509 8.4998 106.509 1.03765 106.509C1.03765 96.2979 0.92013 86.2893 1.18035 76.281C1.21393 74.9876 2.93477 73.3813 4.26107 72.5531C7.78629 70.3469 11.5218 68.487 16.3397 65.8498C12.621 64.5648 9.994 63.7112 7.40015 62.7559C3.32091 61.2598 0.752245 58.9519 0.827794 53.914C1.06284 37.515 0.920131 21.1075 0.920131 4.70842C0.920131 3.27139 0.920131 1.84284 0.920131 0.0507812C8.31513 0.0507812 15.3324 0.0507812 22.8281 0.0507812C22.8281 12.4092 22.8281 24.8268 22.8281 38.2335C24.465 36.8133 25.4216 36.1202 26.219 35.2748C34.8983 26.0441 45.4576 24.4465 56.7895 28.5462C67.97 32.5952 71.4112 42.164 72.5864 53.1533C72.8383 55.503 74.4668 58.1237 76.2376 59.7806C78.7643 62.1556 82.0045 63.7619 86.1173 66.4754C82.7851 68.2083 80.1913 69.8311 77.396 70.9384C73.854 72.3331 72.1583 74.2606 72.3346 78.4787C72.7124 87.6841 72.4521 96.9151 72.4521 106.425C65.0236 106.425 57.9979 106.425 50.2508 106.425C50.2508 98.952 50.2843 91.3951 50.2424 83.8379C50.1752 72.4602 48.3536 72.0967 61.96 66.9233C62.8162 66.6021 63.6725 66.2808 65.8294 65.461C61.2885 63.246 57.6202 61.809 54.3468 59.7468C52.0719 58.3179 49.7807 56.3485 48.4712 54.0576C45.6423 49.0788 41.5882 46.974 36.1491 47.2108C31.0877 47.4303 27.3019 49.6875 25.0102 54.421C23.869 56.7711 24.1124 58.428 26.4792 59.6282C30.433 61.6317 34.3947 63.6352 38.667 65.7907C34.4031 67.904 30.1643 69.6622 26.3281 72.0883C24.792 73.0601 23.3402 75.4693 23.2814 77.2697C22.954 86.8305 23.1135 96.4077 23.1135 106.509Z"
          fill="none"
        />
      </svg>
    </div>
  );
};

export default H;
