import * as React from "react";
import PointText from "src/components/PointText";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";
import classes from "./Start.module.scss";
import { Logo } from "./Logo";
import OneScreenWrapper from "../OneScreenWrapper";

const Start: React.SFC<{}> = () => {
  return (
    <OneScreenWrapper>
      <div
        sx={{
          width: ["80%", "unset"],
          marginLeft: ["0", "15vw"],
        }}
      >
        <PointText text="диджитал супер агентство" />
        <div
          sx={{
            width: "100%",
          }}
        >
          <Logo />
        </div>
      </div>
      <Circle />
    </OneScreenWrapper>
  );
};

const Circle: React.SFC<{}> = () => {
  return (
    <div
      sx={{
        borderColor: "primary",
        position: "relative",
        marginLeft: [0, "14px"],
        width: "inherit",
      }}
    >
      <div
        className={classes.circle}
        sx={{
          position: "relative",
          width: ["45vh", "64vh"],
          height: ["45vh", "64vh"],
          left: [0, "-22%"],
          right: "0",
          top: 0,
        }}
      />
      <div
        className={classes.mainGroup}
        sx={{
          left: [0, "-12%"],
          right: ["-15%", 0],
          top: ["25%", 0],
        }}
      >
        <img
          src="./mainGroup.svg"
          alt=""
          sx={{
            width: ["60vw", "79%"],
            left: "0",
            right: "0",
            marginLeft: ["auto", "-12%"],
            margin: ["auto", "unset"],
            display: ["flex", "unset"],
          }}
        />
      </div>
    </div>
  );
};

export default Start;
