// @jsx jsx
import * as React from "react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";

const A: React.SFC<{}> = () => {
  return (
    <div
      sx={{
        position: "sticky",
        right: "40vw",
        opacity: 0.3,
        display: ["none", "flex"],
        flexDirection: "column",
        justifyContent: "flex-start",
        marginTop: "-2%",
        zIndex: 100,
      }}
    >
      <svg
        height="100"
        viewBox="0 0 88 82"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        sx={{
          "& > path": {
            stroke: "primary",
          },
        }}
      >
        <path
          d="M18.0936 43.1523C16.6078 40.1011 15.5001 37.8185 14.0983 34.9445C26.3616 30.363 38.138 31.3014 49.9732 34.3696C52.0802 23.5158 42.2592 18.7569 33.0263 19.5092C26.2189 20.0584 19.5038 21.7575 12.2935 23.0172C10.623 17.9029 8.82704 12.434 6.92992 6.63503C15.1308 2.15492 23.9188 0.489638 32.9003 0.100799C38.1632 -0.127414 43.5939 0.151541 48.7476 1.1828C63.3949 4.11604 71.1593 13.3889 72.141 30.2363C72.5103 36.4744 74.3235 40.1939 79.9725 42.3747C82.1886 43.2287 84.1357 44.767 87.1073 46.5253C83.7248 48.0045 81.0638 49.222 78.3608 50.3377C74.2816 52.0284 71.9312 54.4629 72.3089 59.5008C72.7958 65.9419 72.4264 72.451 72.4264 79.3151C65.0066 79.3151 57.8803 79.3151 50.6112 79.3151C50.5104 77.2525 50.4181 75.3334 50.3677 74.2852C44.3242 76.5424 38.3143 79.6528 31.935 80.9716C23.667 82.6707 15.5001 80.98 8.68434 75.4942C1.28094 69.5348 -0.943559 61.4026 0.886403 52.4003C1.3397 50.1853 4.24374 47.9116 6.56057 46.7959C10.0858 45.0883 14.0815 44.3614 18.0936 43.1523ZM60.0792 48.4271C59.9029 48.0298 59.7266 47.6326 59.5419 47.2269C58.9631 47.0323 58.3587 46.6097 57.8131 46.6773C47.3374 47.9201 36.8369 49.0189 26.4371 50.7265C24.7834 50.9971 22.3159 54.2514 22.4838 55.8999C22.752 58.5961 24.2462 61.9522 26.3364 63.5243C31.9434 67.7512 44.618 65.6291 48.2104 59.5515C51.1568 54.5727 53.893 49.9658 60.0792 48.4271Z"
          fill="black"
        />
      </svg>
    </div>
  );
};

export default A;
