// @jsx jsx
import React from "react";
import { Link } from "gatsby";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";

const Anchor: React.SFC<Props> = (props) => {
  return (
    <Link
      {...props}
      activeClassName="active"
      sx={{
        color: "primary",
        textDecoration: "none",
        margin: "0 auto",
      }}
    />
  );
};

interface Props<T = null> {
  /** A class to apply when this Link is active */
  activeClassName?: string;
  /** Inline styles for when this Link is active */
  activeStyle?: object;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  /** Class the link as highlighted if there is a partial match via a the `to` being prefixed to the current url */
  partiallyActive?: boolean;
  /** Used to declare that this link replaces the current URL in history with the target */
  replace?: boolean;
  /** Used to pass state data to the linked page.
   * The linked page will have a `location` prop containing a nested `state` object structure containing the passed data.
   */
  state?: T;
  /** The URL you want to link to */
  to: string;
}

export default Anchor;
