import * as React from "react";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";

type Props = {
  styles?: object;
};

const OneScreenWrapper: React.SFC<Props> = (props) => {
  return (
    <div
      sx={{
        width: ["100%", "70vw"],
        display: "flex",
        justifyContent: "space-between",
        margin: ["24px auto", "0 10vw 0 0"],
        height: ["unset", "100%"],
        zIndex: 500,
        flexDirection: ["column", "row"],
        ...(props.styles || {}),
      }}
    >
      {props.children}
    </div>
  );
};

export default OneScreenWrapper;
