/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { Component } from "react";
import OneScreenWrapper from "src/routes/Main/OneScreenWrapper";
import PointText from "../PointText";
import ContactForm from "./ContactForm";
// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Styled, jsx } from "theme-ui";

class ContactFormContainer extends Component<any, { submitting?: boolean }> {
  state = {
    submitting: false,
  };

  handleSubmit = async (
    ev: React.SyntheticEvent<HTMLFormElement, Event>
  ): Promise<void> => {
    ev.preventDefault();
    if (this.state.submitting) {
      return;
    }

    this.setState({ submitting: true });

    const { name, text, email } = ev.currentTarget;

    await this.sendForm({
      // @ts-ignore
      name: name.value,
      // @ts-ignore
      text: text.value,
      email: email.value,
    });

    // @ts-ignore
    (name.value = ""), (text.value = ""), (email.value = "");
  };

  sendForm = async (data) => {
    try {
      await window.fetch(
        "https://us-central1-shakeme-requests-sender.cloudfunctions.net/helloWorld",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
          mode: "no-cors", // 'cors' by default,
        }
      );
      try {
        if (process.env.NODE_ENV === "production") {
          // @ts-expect-error
          // fbq("track", "Contact");
        }
      } catch (e) {
        console.error("cant send event to facebook");
      }
      alert("Заявка успешно отправлена");
    } catch (e) {
      console.error(e);
      alert("Произошла ошибка, повторите позднее или напишете нам на почту");
    }

    this.setState({ submitting: false });
  };

  public render() {
    return (
      <div
        id="#contacts"
        sx={{
          ml: ["0", "10vw"],
        }}
      >
        <OneScreenWrapper>
          <div>
            <PointText text="cвяжитесь с нами" />
            <Styled.h1
              sx={{
                color: "secondary",
                width: ["unset", "330px"],
              }}
            >
              Напишите нам
            </Styled.h1>
          </div>

          <ContactForm
            handleSubmit={this.handleSubmit}
            submitting={this.state.submitting}
          />
        </OneScreenWrapper>
      </div>
    );
  }
}

export default ContactFormContainer;
