// @jsx jsx
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { jsx } from "theme-ui";
import React from "react";
import Case from "./Case/Case";
import classes from "./Cases.module.scss";
import potential from "src/images/potential.svg";
import A from "src/components/letters/a";
import { graphql, useStaticQuery } from "gatsby";
import PointText from "src/components/PointText";

const query = graphql`
  query {
    ragu: file(name: { eq: "ragu" }) {
      childImageSharp {
        fluid(maxWidth: 438, maxHeight: 438) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    suvorov: file(name: { eq: "suvorov" }) {
      childImageSharp {
        fluid(maxWidth: 438, maxHeight: 438) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Cases: React.SFC<{}> = () => {
  const data = useStaticQuery(query);
  return (
    <div
      className={classes.casesWrapper}
      sx={{
        flexDirection: ["column", "row"],
      }}
      id="#cases"
    >
      <Case
        header="Potential Platform"
        link="https://potential-platform.com/"
        img={potential}
      />

      <Case
        header="RAGU Ресторан"
        link="https://ragu-spb.ru/"
        gatsbyImg={data.ragu.childImageSharp.fluid}
      />
      <Case
        header="Проект для федерального музея"
        link=""
        imgOverlay={<PointText text="в разработке" />}
        gatsbyImg={data.suvorov.childImageSharp.fluid}
      />
      <A />
    </div>
  );
};

export default Cases;
